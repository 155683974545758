import "./App.css";
import React, { useContext } from "react";
import Home from "./pages/Home";
import Basic from "./pages/Basic";
import Standard from "./pages/Standard";
import Premium from "./pages/Premium";
import Cartpage from "./pages/Cartpage";
import Booking from "./pages/Booking";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Footer from "./components/Footer";
import shoppingCartIcon from "./shoppingCart.svg";
import menu from "./menu.svg";
import { Link, Route, Routes } from "react-router-dom";
import UpdateProfile from "./pages/UpdateProfile";
import ForgotPassword from "./pages/ForgotPassword";
import PrivateRoute from "./pages/PrivateRoute";
import accountCircle from "./account_circle.svg";
import { CartContext } from "./contexts/ShoppingCartContext";
import Cart from "./pages/Cartpage";
import PostPaymentUpload from "./pages/PostPaymentUpload";
import Services from "./pages/Services";
import { useAuth } from "./contexts/AuthContext";
import { getFunctions, httpsCallable } from "firebase/functions";
import Stripe from "stripe";

function App() {
  const {
    cartItems,
    addToCart,
    cartNumber,
    removeFromCart,
    handleShow,
  } = useContext(CartContext);

  const { currentUser } = useAuth();

  async function checkout() {
    const stripe_products = [
      { id: 1, quantity: cartItems[1] },
      { id: 2, quantity: cartItems[2] },
    ];

    const temparray = [];
    stripe_products.map((item) => {
      if (item.quantity) {
        temparray.push(item);
      }
    });

    // const functions = getFunctions();
    // const createStripeCheckout = httpsCallable(functions, 'createStripeCheckout')

    // createStripeCheckout(JSON.stringify({items: temparray}))
    // .then(res =>{
    //   window.location =  res.data.url
    // })
    //https://createstripecheckout-peivuuhq7q-uc.a.run.app
    //createstripecheckout-peivuuhq7q-uc.a.run.app/
    //dev version works /.netlify/functions/checkout

    const data = await fetch("/.netlify/functions/checkout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(temparray),
    })
      .then((res) => {
        if (res.ok) return res.json();
        return res.json().then((json) => Promise.reject(json));
      })
      .catch((e) => {
        console.error(e.error);
      });
    window.location = data;
  }

  return (
    <>
      <nav class="header">
        <Link
          className="navServices"
          to="/"
          style={{
            textDecoration: "none",
            color: "inherit",
            fontweight: "100",
          }}
        >
          <img alt="" style={{ width: "20px", margin: "0" }} src={menu}></img>
        </Link>
        <div>
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
            CHADMETER.ORG
          </Link>
        </div>
        <div className="shoppingCart">
          <Link
            to={currentUser ? "/Dashboard" : "login"}
            style={{
              textDecoration: "none",
              color: "inherit",
              fontweight: "100",
              marginRight: "10px",
            }}
          >
            <img
              alt=""
              style={{ width: "20px", margin: "0" }}
              src={accountCircle}
            ></img>
          </Link>
          <img
            alt=""
            className="shoppingCartPic"
            src={shoppingCartIcon}
            onClick={handleShow}
          ></img>
          <span>{cartNumber ? cartNumber : ""}</span>
        </div>
      </nav>
      <div className="App">
        <Cart
          checkout={checkout}
          addCart={addToCart}
          subtractCart={removeFromCart}
        />
        {/* <Services/> */}
        <Routes>
          <Route exact path="/" element={<Home />} />
          {/* <Route path="/Cart" element={<Cart checkout={checkout} addCart={addToCart} subtractCart={removeFromCart}/>}/> */}
          <Route
            path="/Basic"
            element={
              <Basic id={1} checkout={checkout} changeCart={addToCart} />
            }
          />
          <Route
            path="/Standard"
            element={
              <Standard id={2} checkout={checkout} changeCart={addToCart} />
            }
          />
          <Route path="/Premium" element={<Premium />} />
          <Route path="/Booking" element={<Booking />} />
          <Route path="/SignUp" element={<Signup />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          {/* these routes are private */}
          <Route
            path="/update-profile"
            element={
              <PrivateRoute>
                <UpdateProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="/post-payment-upload"
            element={
              
                <PostPaymentUpload />
              
            }
          />
        </Routes>
      </div>
      <Footer className="grainy"/>
    </>
  );
}

export default App;
