import React, { Fragment, useState } from 'react';
import defaultPic from "../blank_profile.png";
import CountUp from 'react-countup';
import FileUpload from '../components/FileUpload';
import {
    ref,
    uploadBytes,
    getDownloadURL,
  } from "firebase/storage";
  import { storage } from "../firebase";
  import { v4 } from "uuid";
import Offer from '../components/offer';
import checked from "../check_box.svg"
import { useAuth } from '../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import {Spinner, Alert} from 'react-bootstrap';
import offers from '../components/offersObject';
// import { process } from '../process';

function ChadscoreDiv(props){
  if ( props.err) return (
    <>
    <Alert variant='danger'>Error, check requirements</Alert>
    </>
  ) 
  else return (
    <>
    <h4>Chadmeter Score: </h4>
    <h1>{props.chad_score?<CountUp end={props.chad_score} />: "*"}/10</h1>
    </>
  )
}

const Home = () => {
    const [imageUpload, setImageUpload] = useState(null);
    const [imageUrls, setImageUrls] = useState(null);
    const [chad_score, setchad_score] = useState(null)
    const [loading, setLoading] = useState(false) 
    const [imageerr, setImageerr] = useState(0)
    const { currentUser } = useAuth()
    const navigate = useNavigate();
  
      async function detectFace(incoming){

      // const apiKey = process.env.REACT_APP_APIKEY
      // const apiSecret = process.env.REACT_APP_APISECRET;
      // const imageFile = incoming
      // const url = process.env.REACT_APP_API;
      const apiKey = 'vpgzmypy-EmpYrxKqUP_PAhYDSV9Gquk'
      const apiSecret = 'O1lI_G0l_k2Wc-IveOqW3rbIz6oPxYGi'
      const imageFile = incoming
      const url = 'https://api-us.faceplusplus.com/facepp/v3/detect'
      // console.log(imageFile)
      const formData = new FormData();
      formData.append('api_key', apiKey);
      formData.append('api_secret', apiSecret);
      formData.append('image_url', imageFile);
      formData.append('return_landmark', '1');
      formData.append('return_attributes', 'gender,age,beauty');
  
      try {
        
        const response = await fetch(url, {
          method: 'POST',
          body: formData
        });
  
        const result = await response.json()
        setImageerr(0)
        // console.log((result.faces[0].face_rectangle))
        // console.log((result.faces[0].attributes.beauty));
        const beauty = result.faces[0].attributes.beauty
        const new_chad_score = beauty.male_score > beauty.female_score ? ((beauty.male_score)/10) : ((beauty.female_score)/10);

        if (currentUser){
        setLoading(false)
        return setchad_score(new_chad_score)
        }else navigate('/SignUp')

      } catch (error) {
        // console.error('Error:', error);
        setLoading(false)
        setTimeout(() => {
        setImageerr(0)
        }, 3000);
      }
    };
  
  
    async function uploadFile(){
      if (imageUpload == null) return;
      setLoading(true)
      //check quality of image right here
    //   process(imageUpload, function (srcEncoded) { });
    // console.log(srcEncoded);
    // You can use srcEncoded here or pass it to another function as needed


      const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);
        uploadBytes(imageRef, imageUpload).then((snapshot) => {
        getDownloadURL(snapshot.ref).then(async (url) => {
          // console.log(snapshot.ref)
          localStorage.setItem('chadimage', url);
            try{
            setImageUrls(url);
             detectFace(url)
            } catch{
              return
            }
        });
      });
      // console.log('uploaded to firebase')

     
    };

  
    const onChange = async e => {
      setImageUrls(null)
      setchad_score(null)
      setImageUpload(e.target.files[0]);
    };
  
    const onSubmit = async e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', imageUpload);
        uploadFile()
    };
  
  return (
    <Fragment>
    <div className="intro" >
    {/* <h1>"Helping Men Improve Their Looks"</h1> */}
    <h1 className="" style={{ fontSize: 'min(60px)', margin: "40px", fontWeight:"800", color: "#171717"}}>Looks Analyses and Coaching</h1>
    </div>
    
    <div className="sectionbox">
      <div className="introText">
        <h1 style={{ marginBottom: "20px", fontWeight:"800"}}>How Attractive Are <br />You From 1-10?</h1>

          <p><b>-</b> Artificial Intelligence based rating</p>
          <p><b>-</b> 100+ Facial landmarks tracked</p>
          <p><b>-</b> 30,000+ Images in dataset</p>
        <h1 style={{ marginTop: "80px", marginBottom: '30px', fontWeight:"100"}}>Before You Upload</h1>
        <button className="requirements">Frontal Pose<span><img alt="" className="checked" src={checked}></img></span></button>
        <button className="requirements">Neutral Expression<span><img alt="" className="checked" src={checked}></img></span></button>
        <button className="requirements">Adequate Lighting<span><img alt="" className="checked" src={checked}></img></span></button>
        <p className="extra-req">*Image must also be jpeg or png less than 2mb</p>
      </div>
    
      <div className="rating">
        <div className="imagebox"><img alt="" src={imageUrls? `${imageUrls}` : defaultPic}></img></div>
          <FileUpload onChange={onChange} onSubmit={onSubmit}/>
        <div className="ratingbox">
          {loading?<Spinner animation="grow" />:<ChadscoreDiv chad_score={chad_score} err={imageerr}/>}
        </div>
      </div>
    </div>
    <h1 style={{margin: '50px'}}>Our Services</h1>
    <div style={{minHeight: '100%'}} className="card-container">
      {offers.map(item =>{
        return <Offer title={item.title} price={item.price} details={item.details}/>
      })}
    </div>
    </Fragment>
  );
};

export default Home;