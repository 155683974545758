import React, {useRef, useState} from 'react'
import { Form, Button, Card, Alert } from 'react-bootstrap'
import { Container } from "react-bootstrap"
import { useAuth } from '../contexts/AuthContext'
import { Link, useNavigate } from 'react-router-dom'


export default function Login() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const {login} = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false) 
    const navigate = useNavigate();


    async function handleSubmit(e){
        e.preventDefault()
        try{
        setError('')
        setLoading(true)
        await login(emailRef.current.value, passwordRef.current.value)
        navigate('/');
        } catch {
            setError('Failed to sign in')
        }

        setLoading(false)
    }
  return (
    <Container
    className="d-flex align-items-center justify-content-center "
      style={{ minHeight: "100px" , fontSize: '16px', }}
    >
    <div className="w-100" style={{ maxWidth: "400px" , overflow: "hidden"}}>
    <Card className="grainy">
        <Card.Body>
            <h2 className='text-center mb-4'>Log In</h2>
            <p className='text-center mb-4'>Log In to see Chadmeter Score</p>
            {error && <Alert variant='danger'>{error}</Alert>}
            <Form onSubmit={handleSubmit}>
                <Form.Group id='email'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control className='mb-4' type='email' ref={emailRef} required/>
                </Form.Group>
                <Form.Group id='password'>
                    <Form.Label>Password</Form.Label>
                    <Form.Control className='mb-4' type='password' ref={passwordRef} required/>
                </Form.Group>
                <Button style={{backgroundColor: "black", border: 'none'}} disabled={loading} className='w-100' type='submit'>Log In</Button>
            </Form>
            <div className="w-100 text-center mt-3">
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
        </Card.Body>
    </Card>
    <div className='w-100 text-center mt-2'>
        Need an account? <Link to='/SignUp'>Sign Up</Link> it's FREE!
    </div>
    </div>
    </Container>        
  )
}
