import React, { Fragment } from 'react';

const CustomFileUpload = (props) => {

  return (
    <Fragment>
      <form >
        <div className='custom-file mb-4'>
          <input
            type='file'
            className='custom-file-input'
            id='customFile'
            accept="image/jpeg, image/png, image/jpg"
            onChange={(e)=>props.onChange(e, props.id)}
          />
        </div>
      </form>
    <button className="button" onClick={(e)=>props.onSubmit(e, props.id)}> Upload</button>
    </Fragment>
  );
};

export default CustomFileUpload;