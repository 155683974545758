import React, { Fragment } from 'react';

const FileUpload = (props) => {

  return (
    <Fragment>
      <form >
        <div className='custom-file mb-4'>
          <input
            type='file'
            className='custom-file-input'
            id='customFile'
            accept="image/jpeg, image/png, image/jpg"
            onChange={props.onChange}
          />
        </div>
      </form>
    <button className="button" onClick={props.onSubmit}> Upload</button>
    </Fragment>
  );
};

export default FileUpload;