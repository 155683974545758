import React, { useState } from "react"
import { Card, Button, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useNavigate } from "react-router-dom"
import { useEffect } from "react"

export default function Dashboard() {
  const [error, setError] = useState("")
  const { currentUser, logout } = useAuth()
  const navigate = useNavigate();
  
  useEffect(()=>{
    if(!currentUser){
      navigate('/Login');
    }
  },[])

  async function handleLogout() {
    setError("")

    try {
      await logout()
      navigate('/Login');
    } catch {
      setError("Failed to log out")
    }
  }

  return (
    <>
      <Card className="grainy"> 
        <Card.Body>
          <h2 className="text-center mb-4">Profile</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <strong style={{ fontSize: '16px'}}>Email:</strong> <span style={{ fontSize: '16px'}}>{currentUser.email}</span>
          <Link style={{backgroundColor: "black", border: 'none'}} to="/update-profile" className="btn btn-primary w-100 mt-3">
            Update Profile
          </Link>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        <Button variant="link" onClick={handleLogout}>
          Log Out
        </Button>
      </div>
    </>
  )
}