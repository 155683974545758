import React, {useState, useEffect} from 'react'

export const CartContext = React.createContext()

const getCartNumber = (Cart) => {
    let cartNum = 0;
    for (const id in Cart) {
        if (Cart.hasOwnProperty(id)) {
          cartNum += Cart[id];
        }
      }
    return cartNum;
  };

const products = {
    1: 0,
    2: 0
}

const getLocalCart = () => {
    const newCartData = JSON.parse(localStorage.getItem('shoppingCart'))
    if(!newCartData){
      return products
    }else {
      return newCartData
    }
  }

export default function ShoppingCartContextProvider(props) {

    const [cartItems, setCartItems] = useState(getLocalCart)

    const [cartNumber, SetCartNumber] = useState(0)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const handleShow = () => setShow(true);

    useEffect(() => {
        SetCartNumber(getCartNumber(cartItems));
      }, [cartItems]);

    useEffect(() => {
        localStorage.setItem('shoppingCart', JSON.stringify({...cartItems}))
      }, [cartItems]);

    

    const addToCart = (itemId) => {
        setCartItems((prev) =>({ ...prev, [itemId]: prev[itemId] + 1 }));
      };
    
    const removeFromCart = (itemId) => {
        setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] - 1 }));
      };

    
    
    const contextValues = {
        cartItems,
        addToCart,
        removeFromCart,
        cartNumber,
        show,
        handleClose,
        handleShow
    }
  return (
    <CartContext.Provider value={contextValues}>{props.children}</CartContext.Provider>
  )
}
