import React, {useRef, useState} from 'react'
import { Form, Button, Card, Alert } from 'react-bootstrap'
import { Container } from "react-bootstrap"
import { useAuth } from '../contexts/AuthContext'
import { Link, useNavigate } from 'react-router-dom'

export default function Signup() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const {signup} = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false) 
    const navigate = useNavigate();

    async function handleSubmit(e){
        e.preventDefault()
        if(passwordRef.current.value !== passwordConfirmRef.current.value){
            return setError('Passwords do not match')
        }
        try{
        setError('')
        setLoading(true)
        await signup(emailRef.current.value, passwordRef.current.value)
        navigate('/');
        } catch {
            setError('Failed to create an account')
        }

        setLoading(false)
    }
  return (
    <Container
    className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100px" , fontSize: '16px', }}
    >
    <div className="w-100" style={{ maxWidth: "400px" , overflow: "hidden"}}>
    <Card className="grainy">
        <Card.Body>
            <h2 className='text-center mb-4'>Sign Up for Free</h2>
            <p className='text-center mb-4'>Sign up to see Chadmeter Score</p>
            {error && <Alert variant='danger'>{error}</Alert>}
            <Form onSubmit={handleSubmit}>
                <Form.Group id='email'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control className='mb-4' type='email' ref={emailRef} required/>
                </Form.Group>
                <Form.Group id='password'>
                    <Form.Label>Password</Form.Label>
                    <Form.Control className='mb-4' type='password' ref={passwordRef} required/>
                </Form.Group>
                <Form.Group id='password-confirm'>
                    <Form.Label>Password Confirmation</Form.Label>
                    <Form.Control className='mb-4' type='password' ref={passwordConfirmRef} required/>
                </Form.Group>
                <Button style={{backgroundColor: "black", border: 'none'}} disabled={loading} className='w-100' type='submit'>Sign Up</Button>
            </Form>
        </Card.Body>
    </Card>
    <div className='w-100 text-center mt-2'>
        Already have an account? <Link to='/Login'>Login</Link>
    </div>
    </div>
    </Container>        
  )
}
