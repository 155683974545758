import React, { useContext } from 'react';
import sample_report1 from "../sample_report1.png"
import sample_report2 from "../sample_report2.png"
import { MyCarousel } from "../components/Carousel";
import { CartContext } from '../contexts/ShoppingCartContext';


const Standard = (props) => {
  const items = [
    sample_report1,
    sample_report2
      ];
  const {addToCart} = useContext(CartContext)
  return (
    <div className='sectionbox'>
        <MyCarousel items={items}/>
        <div className='descriptionBox'>
        <div className='basictext'>
          <h4>Standard Aesthetics Report</h4>
          <h4>$50.00</h4>
          <p className='description'>A very detailed assessment of the face as a whole. </p>
<p className='description'>We consider your facial features and provide detailed guidelines to improve your looks based on different budgets. </p>
<p className='description'>We also include custom face morphs, allowing you to visualise your face after recommended improvements.</p>
        </div>
        <div classname="descriptionButtons">
        <button className='button' onClick={()=>addToCart(props.id)}>Add to Cart</button>
        <button className='button' onClick={()=>props.checkout(props.id)}>Order Now</button>
        </div>
        </div>
        
    </div>
  );
};

export default Standard;