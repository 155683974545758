import React, { Fragment, useState } from 'react';

function Booking() {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [age, setAge] = useState('');
  const [occupation, setOccupation] = useState('');
  const [country, setCountry] = useState('');
  const [incomeRange, setIncomeRange] = useState('');
  const [willing, setWilling] = useState('')
  const [motivation, setMotivation] = useState('')
  const [challenge, setChallenge] = useState('')

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleAgeChange = (event) => {
    setAge(event.target.value);
  };

  const handleOccupationChange = (event) => {
    setOccupation(event.target.value);
  };

  const handleMotivationChange = (event) => {
    setMotivation(event.target.value);
  };

  const handleChallengeChange = (event) => {
    setChallenge(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleIncomeChange = (event) => {
    setIncomeRange(event.target.value);
  };

  const handleWillingChange = (event) => {
    setWilling(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const calendly = 'https://calendly.com/chadmeter/30min'
    window.location = calendly
    // You can perform any validation or submission logic here
    console.log('Submitted email:', email);
    console.log('Submitted name:', name);
    console.log('Submitted age:', age);
    console.log('Submitted occupation:', occupation);
    console.log('Submitted country:', country);
    console.log('Submitted income range:', incomeRange);
    console.log('Submitted motivation:', motivation);
    console.log('Submitted challenge:', challenge);
    // Reset the fields
    setEmail('');
    setName('');
    setAge('');
    setOccupation('');
    setCountry('');
    setIncomeRange('');
    setWilling('');
    setMotivation('');
    setChallenge('');
  };

  return (
    <Fragment>
    <h2>Coaching Application Form</h2>
    <div className='forms'>
      <form onSubmit={handleSubmit}>
        <label>
          Email:
          <br />
          <input
            className='bookingInput'
            type="email"
            value={email}
            onChange={handleEmailChange}
            required
          />
        </label>
        <br />
        <label>
          Name:
          <br />
          <input
            className='bookingInput'
            type="text"
            value={name}
            onChange={handleNameChange}
            required
          />
        </label>
        <br />
        <label>
          Age:
          <br />
          <input
            className='bookingInput'
            type="number"
            value={age}
            onChange={handleAgeChange}
            required
          />
        </label>
        <br />
        <label>
          Current Occupation:
          <br />
          <input
            className='bookingInput'
            type="text"
            value={occupation}
            onChange={handleOccupationChange}
            required
          />
        </label>
        <br />
        <label>
          Country of Residence:
          <br />
          <input
            className='bookingInput'
            type="text"
            value={country}
            onChange={handleCountryChange}
            required
          />
        </label>
        <br />
        <label>
          What motivates you to improve your attractiveness:
          <br />
          <input
            className='bookingInput'
            type="text"
            value={motivation}
            onChange={handleMotivationChange}
            required
          />
        </label>
        <br />
        <label>
            What are the biggest challenges youve faced with improving your looks?
          <br />
          <input
            className='bookingInput'
            type="text"
            value={challenge}
            onChange={handleChallengeChange}
            required
          />
        </label>
        <br />
        <div style={{margin: "0px"}}>
          <p>What is your monthly income?</p>
          <label>
            <input
              type="radio"
              value="0-5000"
              checked={incomeRange === '0-5000'}
              onChange={handleIncomeChange}
              required
            />
            $0-$5000
          </label>
          <br />
          <label>
            <input
              type="radio"
              value="5000-10000"
              checked={incomeRange === '5000-10000'}
              onChange={handleIncomeChange}
              required
            />
            $5000-$10000
          </label>
          <br />
          <label>
            <input
              type="radio"
              value="10000+"
              checked={incomeRange === '10000+'}
              onChange={handleIncomeChange}
              required
            />
            $10000+
          </label>
        </div>
        <br />
        <div>
          <p>Are you willing to make financial investment to get this problem solved?</p>
          <label>
            <input
              type="radio"
              value="Yes"
              checked={willing === 'Yes'}
              onChange={handleWillingChange}
              required
            />
            Yes
          </label>
          <br />
          <label>
            <input
              type="radio"
              value="No"
              checked={willing === 'No'}
              onChange={handleWillingChange}
              required
            />
            No
          </label>
          <br />
          <label>
            <input
              type="radio"
              value="Maybe"
              checked={willing === 'Maybe'}
              onChange={handleWillingChange}
              required
            />
            Maybe
          </label>
        </div>
      </form>
    </div>
    <button className='button' onClick={handleSubmit} style={{marginTop: "50px"}}>Submit</button>
    </Fragment>
  );
}

export default Booking;
