import React from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';

const Offer = (props) => {

  return (
    <Card className='offerCardBody grainy'
    style={{ 
      minWidth: '350px', minHeight: '400px', marginRight: '25px', marginLeft: '25px', marginBottom: '25px',
      flexBasis: "auto"
  }}
    >
<Card.Body className='offerCardBody' style={{      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between'

      }}>
  <Card.Title style={{margin: '15px'}}>{props.title}</Card.Title>
  <Card.Text >
        <ul>
        {props.details.map(detail =>{
           return <li>{detail}</li>
        })}
        </ul>
  </Card.Text>
      <Link to={props.title}>
        <button style={{marginBottom: '10px'}} className='button'>Explore</button>
      </Link>
</Card.Body>
</Card>
  );
};

export default Offer;

