import React, {useContext} from 'react';
import offers from "./offersObject"
import { CartContext } from "../contexts/ShoppingCartContext";

const CartItems = (props) =>{
    const { addToCart, removeFromCart } = useContext(CartContext)
    return (
        <div className='cartbox'>
            {offers.map(item=>{
                if(props.quantity > 0){
                    if(props.id === item.id){
                        return(
                            <div style={{display: "flex", flexDirection: "row"}}>
                            <div >
                                <div>
                                    <p style={{marginBottom: '0'}}>
                                    {item.fullTitle}
                                    </p>
                                    <strong style={{marginRight: "20px"}}>
                                    {item.price}
                                    </strong>
                                    <p style={{ fontWeight: "300", marginRight: "20px" , marginTop: "30px"}}>
                                    Ultra Fast Delivery 3-5 days 
                                    </p>
                                </div>
                                <div className='descriptionButtons'>
                                    <button className='cartbutton' onClick={()=>addToCart(props.id)}>+</button>
                                    <p style={{ fontWeight: "400", fontSize: "12px", marginLeft: "20px", marginRight: "20px" }}>{props.quantity}</p>
                                    <button className='cartbutton' onClick={()=>removeFromCart(props.id)}>-</button>
                                </div>
                                <hr></hr>
                            </div>
                            </div>
                            )
                        }
                }
                else return
            })}
            {/* <p>{props.id}</p>
             */}
        </div>
      );
}
export default CartItems;