import React, {useContext, useState, useEffect} from 'react'
import { 
    createUserWithEmailAndPassword, 
    onAuthStateChanged , 
    signInWithEmailAndPassword,
    signOut,
    sendPasswordResetEmail,
    updateEmail 
} from "firebase/auth"
import { auth } from '../firebase'

const AuthContext = React.createContext()

export function useAuth(){
    return useContext(AuthContext)
}

export default function AuthProvider({children}) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) =>{
            setCurrentUser(user)
            setLoading(false)
        })

        return unsubscribe
    },[])

    function signup(email,password){
        return createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
    setCurrentUser(userCredential.user.email)
  })
    }

    function logout() {
        return signOut(auth)
      }


    function login(email,password){
        return signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
            setCurrentUser(userCredential.user.email)
          })
    }

    function resetPassword(email) {
        return sendPasswordResetEmail(auth, email)
      }

    function update_email(email) {
        return updateEmail(auth.currentUser, email)
      }
    
    function update_password(password) {
        return currentUser.updatePassword(password)
      }

    const value = {
        currentUser,
        signup,
        login,
        logout,
        resetPassword,
        update_email,
        update_password
    }
  return (
    <AuthContext.Provider value={value}>
        {!loading && children}
    </AuthContext.Provider>
  )
}
