import React from 'react';
import sample_report1 from "../sample_report1.png"
import beforePic from "../beforePic.png"
import afterPic from "../afterPic.JPG"
import { MyCarousel } from "../components/Carousel";
import { Link } from 'react-router-dom';


const Premium = () => {
  const items = [
    sample_report1,
    afterPic,
    beforePic
      ];
      
  return (
    <div className='sectionbox'>
        <MyCarousel items={items}/>
        <div className='descriptionBox'>
        <div className='basictext'>
          <h4>Premium Aesthetics Coaching</h4>
          <p className='description'>Full standard package with detailed analyses, improvement guides and visualisations.</p>
          <p className='description'>Custom meal plans, workout routines and product recommendations.</p>
          <p className='description'>We will walk you through your improvement journey, with weekly check-ins and messaging services.</p>
          <p className='description'>We offer Free consultation services, as well. Let's discuss and see if you're the right fit.</p>
        </div>
        <div classname="descriptionButtons">
        <Link to="/Booking">
        <button style={{width: '200px'}} className='button'>Book Free Call</button>
        </Link>
        </div>
        </div>
    </div>
  );
};

export default Premium;