import React from "react";
import Carousel from 'react-bootstrap/Carousel';


export const MyCarousel = (props) => {

  return (
    <Carousel variant="dark">
      {props.items.map(item => {
        return (
          <Carousel.Item>
        <img alt="carousel item" style={{marginLeft: "50px"}} src={item}></img>
      </Carousel.Item>
        )
      })}
    </Carousel>
  );
};