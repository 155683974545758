import sample_report1 from "../sample_report1.png"
import sample_report2 from "../sample_report2.png"

export const offers = [
    {id:1, title: 'Basic', fullTitle: 'Basic Aesthetics Report', price: '$25', details: ['Detailed Facial Analysis', 'Personal Improvement Guide' ], 
    images: [
        {icon: sample_report1},{icon: sample_report2},
      ]},
    {id:2, title: 'Standard', fullTitle: 'Standard Aesthetics Report', price: '$50', details: ['Detailed Facial Analysis', 'Personal Improvement Guide', 'Endpoint Visualisations'],
    images: [
        {icon: sample_report1},{icon: sample_report2},
      ]},
    {id:3, title: 'Premium', fullTitle: 'Premium Aesthetics Coaching', price: '$80/week', details: ['Detailed Facial Analysis', 'Personal Improvement Guide', 'Endpoint Visualisations','Full Standard Package', 'Personal Looks and Fitness coach', 'Weekly Check Ins'],
    images: [
        {icon: sample_report1},{icon: sample_report2},
      ]}
]

export default offers