// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import "firebase/auth";
import { getAuth } from "firebase/auth";
// Required for side-effects
import { getFirestore } from "firebase/firestore";
import * as dotenv from "dotenv";

dotenv.config();

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// const firebaseConfig = {
//   apiKey: "AIzaSyAc8KAutAA5hbxeac1pKYdvtHKZInoPFgE",
//   authDomain: "chadmeter.firebaseapp.com",
//   projectId: "chadmeter",
//   storageBucket: "chadmeter.appspot.com",
//   messagingSenderId: "798326143771",
//   appId: "1:798326143771:web:43c5c1be865c835ef231be",
//   measurementId: "G-4QPC2S1HTG"
// };

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth();
export const storage = getStorage(app);
export const db = getFirestore(app);
