import React, {useState} from 'react'
import {
    ref,
    uploadBytes,
    getDownloadURL,
  } from "firebase/storage";
  import { storage } from "../firebase";
  import { v4 } from "uuid";
  import Card from 'react-bootstrap/Card';
  import CustomFileUpload from "../components/CustomFileUpload"
  import checked from "../check_box.svg"
  import front_face from "../front_face.png"
  import side_face from "../side_face.png"



export default function PostPaymentUpload() {

    const pics = [front_face, side_face]
    const [frontImageUpload, setFrontImageUpload] = useState(null);
    const [sideImageUpload, setSideImageUpload] = useState(null);
    const [frontImageUrls, setFrontImageUrls] = useState(pics[0]);
    const [sideImageUrls, setSideImageUrls] = useState(pics[1]);


    async function uploadFile(id, imageUpload){
        if (imageUpload == null) return;
        const identity = id
        console.log(id)
        const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);
        uploadBytes(imageRef, imageUpload).then((snapshot) => {
          getDownloadURL(snapshot.ref).then(async (url) => {
            console.log(snapshot.ref)
            console.log(url)
            if(identity === 1){
              console.log(identity)
              setFrontImageUrls(url)
            }
            else{
              console.log(identity)
              setSideImageUrls(url)
            }
          });
        });
      };
  
      const onChange = (e, id) =>{
        // setImageUrls(null)
        console.log(id)
        if(id === 1){
        setFrontImageUpload(e.target.files[0]);
        }
        else{setSideImageUpload(e.target.files[0])}
      };
    
      const onSubmit = (e, id) =>{
          e.preventDefault();
          // const formData = new FormData();
          // formData.append('file', imageUpload);
          console.log(id)
          if(id === 1){
            uploadFile(id, frontImageUpload)
            }
            else{uploadFile(id, sideImageUpload)}
      };

      
  return (
    <div className= 'postpayment'>
    <div style={{display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
    <h1 style={{ marginTop: "30px", marginBottom: '20px'}}>What to Upload</h1>
        <button className="requirements">1 Frontal Pose<span><img alt="" className="checked" src={checked}></img></span></button>
        <button className="requirements">1 Side Pose<span><img alt="" className="checked" src={checked}></img></span></button>
        <button className="requirements">Neutral Expression<span><img alt="" className="checked" src={checked}></img></span></button>
        <button className="requirements">Adequate Lighting<span><img alt="" className="checked" src={checked}></img></span></button>
    </div>
    <Card style={{ maxWidth: '500px', minWidth:'450px', height:"600px", marginLeft: "25px", marginRight: '25px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <Card.Title>Upload Front View</Card.Title>
        <div style={{width: '250px', height: "300px", overflow: 'hidden'}}>
        <img alt='front face' style={{width: '250px'}} src={frontImageUrls}></img>
        </div>
        <Card.Text>
          Upload an image of yourself from the front
        </Card.Text>
        <CustomFileUpload id={1} onChange={onChange} onSubmit={onSubmit}/>
      </Card.Body>
    </Card>
    <Card style={{ maxWidth: '500px', minWidth:'450px', height:"600px", marginLeft: "25px", marginRight: '25px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <Card.Title>Upload Side View</Card.Title>
        <div style={{width: '250px', height: "300px", overflow: 'hidden'}}>
        <img alt='side face' style={{width: '250px'}} src={sideImageUrls}></img>
        </div>
        <Card.Text>
          Upload an image of yourself from the side.
        </Card.Text>
        <CustomFileUpload id={2} onChange={onChange} onSubmit={onSubmit}/>
      </Card.Body>
    </Card>
    </div>
  )
}
