import React from 'react';
import insta from "../insta.svg"

const Footer = () => {

  return (
    <footer className="footer">
    <p>© CHADMETER,   built by dumebi.</p>
    <a style={{ marginBottom: '12px'}} href="https://www.instagram.com/dume.bi/"><img alt="" className="insta" src={insta}></img></a>
  </footer>
  );
};

export default Footer;