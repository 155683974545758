import React, { useContext } from 'react';
import sample_report1 from "../sample_report1.png"
import sample_report2 from "../sample_report2.png"
import { MyCarousel } from "../components/Carousel";
import { CartContext } from '../contexts/ShoppingCartContext';

const Basic = (props) => {
const items = [
sample_report1,
sample_report2
  ];
  const {addToCart} = useContext(CartContext)
  
  return (
    <div className='sectionbox'>
        <MyCarousel items={items}/>
        <div className='descriptionBox'>
        <div className='basictext'>
          <h4>Basic Aesthetics Report</h4>
          <h4>$25.00</h4>
          <p className='description'>A quick assessment of the face as a whole, without going into too much depth. </p>
          <p className='description'>We look over your facial features and provide rough guidelines to improve your looks.</p>
        </div>
        <div classname="descriptionButtons">
        <button className='button' onClick={()=>addToCart(props.id)}>Add to Cart</button>
        <button className='button' onClick={()=>props.checkout(props.id)}>Order Now</button>
        </div>
        </div>
    </div>
  );
};

export default Basic;