import React, { useContext} from 'react';
import CartItems from '../components/CartItems';
import { Link, useNavigate } from 'react-router-dom';
import { CartContext } from '../contexts/ShoppingCartContext';
import offers from "../components/offersObject"
import Offcanvas from 'react-bootstrap/Offcanvas';

const Cart = (props) =>{
  
  const { cartItems, addToCart, show, removeFromCart, handleClose} = useContext(CartContext)
    return (
      <>
      <Offcanvas className="grainy" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Shopping Cart</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        {offers.map(item=>{
              if (cartItems[item.id] !== 0)
                return (
                <CartItems id={item.id} quantity={cartItems[item.id]} addCart={addToCart} subtractCart={removeFromCart}/>
                )
              })}
        <div style={{ marginTop: "20px"}}>
            <button onClick={handleClose} className='button'style={{ width: "180px", marginRight: "20px" }}>Continue Shopping</button>
            <button className='button' onClick={()=>props.checkout()} >Checkout</button>
            </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
      );
}

export default Cart;